import { ProductFruits } from 'react-product-fruits';
import { Suspense, lazy, useEffect } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// hooks
import useAuth from '../hooks/useAuth';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from '../components/LoadingScreen';
import CouponsFinancialReport from '../pages/dashboard/Financial/CouponsFinancialReport';
import RestaurantCreateUnauth from '../pages/dashboard/RestaurantCreateUnauth';
import DeliveryManCreateUnauth from '../pages/dashboard/DeliveryManCreateUnauth';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const { user, logout } = useAuth();
  const handleLogout = async () => {
    try {
      await logout();
      Navigate('/');
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    const checkUserLogin = () => {
      const loggedIn = localStorage.getItem('loggedIn');
      const today = new Date().toLocaleDateString();

      if (loggedIn !== today) {
        // Lógica para o usuário fazer login
        // Coloque aqui o código para exibir o formulário de login, redirecionar para a página de login, etc.
        handleLogout();
        localStorage.setItem('loggedIn', today);
      }
    };

    checkUserLogin();
  }, []);
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'first-access', element: <FirstAccess /> },
        { path: 'restaurant-request', element: <RestaurantCreateUnauth /> },
        { path: 'delivery-man-request', element: <DeliveryManCreateUnauth /> },
        { path: 'verify', element: <VerifyCode /> },
        { path: 'new-password/:code', element: <NewPassword /> }
      ]
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '/', element: <Navigate to="/dashboard/app" replace /> },
        {
          path: 'app',
          element: (() => {
            if (user?.type < 3) return <GeneralAppAdm />;
            if (user?.type < 5)
              return (
                <div>
                  <ProductFruits workspaceCode="ADO6wNpCLhxFzJw7" language="pt" user={{ username: user?.name }} />
                  <GeneralAppLic />
                </div>
              );
            return (
              <div>
                <ProductFruits workspaceCode="adwHubuW82Dsdfuj" language="pt" user={{ username: user?.name }} />
                <GeneralApp />
              </div>
            );
          })()
        },
        { path: 'banking', element: <GeneralBanking /> },
        {
          path: 'analytics',
          element: <GeneralAnalytics />
        },
        {
          path: 'financial',
          children: [
            {
              path: '/coupons',
              element: (
                <RoleBasedGuard accessibleRoles={[1, 3]}>
                  <CouponsFinancialReport />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'licensed',
          children: [
            { path: '/', element: <Navigate to="/dashboard/user/list" replace /> },
            {
              path: 'list',
              element: (
                <RoleBasedGuard accessibleRoles={[1, 3]}>
                  <LicensedList />
                </RoleBasedGuard>
              )
            },
            {
              path: 'new',
              element: (
                <RoleBasedGuard accessibleRoles={[1, 3]}>
                  <LicensedCreate />
                </RoleBasedGuard>
              )
            },
            {
              path: 'permissions',
              element: (
                <RoleBasedGuard accessibleRoles={[1]}>
                  <LicensedPermissions />
                </RoleBasedGuard>
              )
            },
            {
              path: '/:name/edit',
              element: (
                <RoleBasedGuard accessibleRoles={[1, 3]}>
                  <LicensedCreate />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'category',
          children: [
            { path: '/', element: <Navigate to="/dashboard/category/list" replace /> },
            {
              path: 'list',
              element: (
                <RoleBasedGuard accessibleRoles={[1]}>
                  <CategoryList />
                </RoleBasedGuard>
              )
            },
            {
              path: 'new',
              element: (
                <RoleBasedGuard accessibleRoles={[1]}>
                  <CategoryCreate />
                </RoleBasedGuard>
              )
            },
            {
              path: '/:name/edit',
              element: (
                <RoleBasedGuard accessibleRoles={[1]}>
                  <CategoryCreate />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'product-category',
          children: [
            { path: '/', element: <Navigate to="/dashboard/product-category/list" replace /> },
            {
              path: 'list',
              element: (
                <RoleBasedGuard field="categorias" accessibleRoles={[5, 6]}>
                  <ProductCategoryList />
                </RoleBasedGuard>
              )
            },
            {
              path: 'new',
              element: (
                <RoleBasedGuard field="categorias" accessibleRoles={[5, 6]}>
                  <ProductCategoryCreate />
                </RoleBasedGuard>
              )
            },
            {
              path: '/:name/edit',
              element: (
                <RoleBasedGuard accessibleRoles={[5, 6]}>
                  <ProductCategoryCreate />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'content-category',
          children: [
            { path: '/', element: <Navigate to="/dashboard/content-category/list" replace /> },
            {
              path: 'list',
              element: (
                <RoleBasedGuard accessibleRoles={[1, 2]}>
                  <ContentCategoryList />
                </RoleBasedGuard>
              )
            },
            {
              path: 'new',
              element: (
                <RoleBasedGuard accessibleRoles={[1, 2]}>
                  <ContentCategoryCreate />
                </RoleBasedGuard>
              )
            },
            {
              path: '/:name/edit',
              element: (
                <RoleBasedGuard accessibleRoles={[1, 2]}>
                  <ContentCategoryCreate />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'marketing-category',
          children: [
            { path: '/', element: <Navigate to="/dashboard/marketing-category/list" replace /> },
            {
              path: 'list',
              element: (
                <RoleBasedGuard accessibleRoles={[1, 2]}>
                  <MarketingCategoryList />
                </RoleBasedGuard>
              )
            },
            {
              path: 'new',
              element: (
                <RoleBasedGuard accessibleRoles={[1, 2]}>
                  <MarketingCategoryCreate />
                </RoleBasedGuard>
              )
            },
            {
              path: '/:name/edit',
              element: (
                <RoleBasedGuard accessibleRoles={[1, 2]}>
                  <MarketingCategoryCreate />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'accounts',
          children: [
            { path: '/', element: <Navigate to="/dashboard/accounts/list" replace /> },
            {
              path: 'list',
              element: (
                <RoleBasedGuard accessibleRoles={[1, 2]}>
                  <AccountsList />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'productV2',
          children: [
            {
              path: 'list',
              element: (
                <RoleBasedGuard field="produtosv2" accessibleRoles={[1, 2, 5, 6]}>
                  <ProductListV2 />
                </RoleBasedGuard>
              )
            },
            {
              path: 'list-models',
              element: (
                <RoleBasedGuard field="produtosv2" accessibleRoles={[1, 2, 5, 6]}>
                  <ProductModelListV2 />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'product',
          children: [
            { path: '/', element: <Navigate to="/dashboard/product/list" replace /> },
            {
              path: 'list',
              element: (
                <RoleBasedGuard field="produtos" accessibleRoles={[5, 6]}>
                  <ProductList />
                </RoleBasedGuard>
              )
            },
            {
              path: 'new',
              element: (
                <RoleBasedGuard field="produtos" accessibleRoles={[5, 6]}>
                  <ProductCreate />
                </RoleBasedGuard>
              )
            },
            {
              path: 'list-models',
              element: (
                <RoleBasedGuard field="produtos" accessibleRoles={[1, 2, 5, 6]}>
                  <ProductModelListV2 />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'model-product',
          children: [
            { path: '/', element: <Navigate to="/dashboard/model-product/list" replace /> },
            {
              path: 'list',
              element: (
                <RoleBasedGuard accessibleRoles={[1, 2]}>
                  <ProductModelList />
                </RoleBasedGuard>
              )
            },
            {
              path: 'all',
              element: (
                <RoleBasedGuard field="produtos" accessibleRoles={[5, 6]}>
                  <ProductModelListShop />
                </RoleBasedGuard>
              )
            },
            {
              path: 'new',
              element: (
                <RoleBasedGuard accessibleRoles={[1, 2]}>
                  <ProductModelCreate />
                </RoleBasedGuard>
              )
            },
            {
              path: '/:name/edit',
              element: (
                <RoleBasedGuard accessibleRoles={[1, 2]}>
                  <ProductModelCreate />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'district',
          children: [
            { path: '/', element: <Navigate to="/dashboard/district/list" replace /> },
            {
              path: 'new',
              element: (
                <RoleBasedGuard field="bairros" accessibleRoles={[1, 2, 3, 4]}>
                  <DistrictCreate />
                </RoleBasedGuard>
              )
            },
            {
              path: '/:name/edit',
              element: (
                <RoleBasedGuard accessibleRoles={[1, 2, 3, 4]}>
                  <DistrictCreate />
                </RoleBasedGuard>
              )
            },
            {
              path: 'list',
              element: (
                <RoleBasedGuard field="bairros" accessibleRoles={[1, 2, 3, 4]}>
                  <DistrictList />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'delivery-fee',
          children: [
            { path: '/', element: <Navigate to="/dashboard/delivery-fee/list" replace /> },
            {
              path: 'list',
              element: (
                <RoleBasedGuard accessibleRoles={[1, 3, 5, 6]}>
                  <DeliveryFeeList />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'heatmap',
          children: [
            {
              path: '/',
              element: (
                <RoleBasedGuard accessibleRoles={[5, 6]}>
                  <HeatMap />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'customer',
          children: [
            { path: '/', element: <Navigate to="/dashboard/customer/list" replace /> },
            {
              path: 'list',
              element: (
                <RoleBasedGuard accessibleRoles={[1, 2, 3, 4]}>
                  <CustomerList />
                </RoleBasedGuard>
              )
            },
            {
              path: 'details/:id',
              element: (
                <RoleBasedGuard accessibleRoles={[1, 2, 3, 4]}>
                  <CustomerAccount />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'reports',
          children: [
            {
              path: '/',
              element: (
                <RoleBasedGuard field="relatórios" accessibleRoles={[1, 2, 3, 4, 5]}>
                  <Reports />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'user',
          children: [
            { path: '/', element: <Navigate to="/dashboard/user/account" replace /> },
            {
              path: 'account',
              element: <UserAccount />
            },
            {
              path: 'new',
              element: (
                <RoleBasedGuard field="colaboradores" accessibleRoles={[3, 5]}>
                  <UserCreate />
                </RoleBasedGuard>
              )
            },
            {
              path: 'asaas',
              element: (
                <RoleBasedGuard accessibleRoles={[3, 5]}>
                  <AsaasCreate />
                </RoleBasedGuard>
              )
            },
            {
              path: '/:name/edit',
              element: (
                <RoleBasedGuard accessibleRoles={[3, 5]}>
                  <UserCreate />
                </RoleBasedGuard>
              )
            },
            {
              path: 'list',
              element: (
                <RoleBasedGuard field="colaboradores" accessibleRoles={[3, 5]}>
                  <UserList />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'request',
          children: [
            { path: '/', element: <Navigate to="/dashboard/request/list" replace /> },
            {
              path: 'list',
              element: (
                <RoleBasedGuard accessibleRoles={[1, 2, 3, 4, 5, 6]}>
                  <RequestList />
                </RoleBasedGuard>
              )
            },
            {
              path: 'list-test',
              element: (
                <RoleBasedGuard accessibleRoles={[1, 2, 3, 4, 5, 6]}>
                  <RequestListTest />
                </RoleBasedGuard>
              )
            },
            {
              path: 'all-list',
              element: (
                <RoleBasedGuard accessibleRoles={[5]}>
                  <RequestAllList />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'coupon',
          children: [
            { path: '/', element: <Navigate to="/dashboard/request/list" replace /> },
            {
              path: 'list',
              element: (
                <RoleBasedGuard field="cupons" accessibleRoles={[1, 2, 3, 4]}>
                  <CouponList />
                </RoleBasedGuard>
              )
            },
            {
              path: '/:name/edit',
              element: (
                <RoleBasedGuard field="cupons" accessibleRoles={[1, 2, 3, 4]}>
                  <CouponCreate />
                </RoleBasedGuard>
              )
            },
            {
              path: 'shop',
              element: (
                <RoleBasedGuard field="cupons" accessibleRoles={[1, 2, 3, 4]}>
                  <CouponShop />
                </RoleBasedGuard>
              )
            },
            {
              path: 'shop/:name/edit',
              element: (
                <RoleBasedGuard accessibleRoles={[1, 2]}>
                  <CouponTemplateCreate />
                </RoleBasedGuard>
              )
            },
            {
              path: 'new-template',
              element: (
                <RoleBasedGuard accessibleRoles={[1, 2]}>
                  <CouponTemplateCreate />
                </RoleBasedGuard>
              )
            },
            {
              path: 'new',
              element: (
                <RoleBasedGuard field="cupons" accessibleRoles={[1, 2, 3, 4]}>
                  <CouponCreate />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'campaigns',
          children: [
            { path: '/', element: <Navigate to="/dashboard/campaigns" replace /> },
            {
              path: 'list',
              element: (
                <RoleBasedGuard accessibleRoles={[1, 2]}>
                  <CampaignsList />
                </RoleBasedGuard>
              )
            },
            {
              path: 'participe',
              element: (
                <RoleBasedGuard accessibleRoles={[5, 6]}>
                  <CampaignsParticipe />
                </RoleBasedGuard>
              )
            },
            {
              path: '/:name/edit',
              element: (
                <RoleBasedGuard accessibleRoles={[1, 2]}>
                  <CampaignsCreate />
                </RoleBasedGuard>
              )
            },
            {
              path: '/new',
              element: (
                <RoleBasedGuard accessibleRoles={[1, 2]}>
                  <CampaignsCreate />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'fidelity',
          children: [
            { path: '/', element: <Navigate to="/dashboard/fidelity/list" replace /> },
            {
              path: 'list',
              element: (
                <RoleBasedGuard field="fidelidades" accessibleRoles={[5, 6]}>
                  <FidelityList />
                </RoleBasedGuard>
              )
            },
            {
              path: 'new',
              element: (
                <RoleBasedGuard field="fidelidades" accessibleRoles={[5, 6]}>
                  <FidelityCreate />
                </RoleBasedGuard>
              )
            },
            {
              path: 'details/:id',
              element: (
                <RoleBasedGuard field="fidelidades" accessibleRoles={[5, 6]}>
                  <FidelityDetails />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'report',
          children: [
            {
              path: 'list',
              element: (
                <RoleBasedGuard accessibleRoles={[1, 2, 3, 5]}>
                  <ReportList />
                </RoleBasedGuard>
              )
            },
            {
              path: 'history',
              element: (
                <RoleBasedGuard accessibleRoles={[1, 2, 3, 5]}>
                  <ReportHistory />
                </RoleBasedGuard>
              )
            },
            {
              path: ':rest/:id',
              element: (
                <RoleBasedGuard accessibleRoles={[1, 2, 3, 5]}>
                  <ReportDetails />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'manual',
          children: [
            {
              path: '/',
              element: (
                <RoleBasedGuard accessibleRoles={[1, 2, 3]}>
                  <Manual />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'delivery-man',
          children: [
            {
              path: 'list',
              element: (
                <RoleBasedGuard accessibleRoles={[3, 4]}>
                  <DeliveryManList />
                </RoleBasedGuard>
              )
            },
            {
              path: '/:name/edit',
              element: (
                <RoleBasedGuard accessibleRoles={[3, 4]}>
                  <DeliveryManCreate />
                </RoleBasedGuard>
              )
            },
            {
              path: 'new',
              element: (
                <RoleBasedGuard accessibleRoles={[3, 4]}>
                  <DeliveryManCreate />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'banner',
          children: [
            {
              path: 'list',
              element: (
                <RoleBasedGuard accessibleRoles={[1, 2]}>
                  <BannerList />
                </RoleBasedGuard>
              )
            },
            {
              path: 'licensed/list',
              element: (
                <RoleBasedGuard accessibleRoles={[3, 4]}>
                  <BannerLicList />
                </RoleBasedGuard>
              )
            },
            {
              path: '/:name/edit',
              element: (
                <RoleBasedGuard accessibleRoles={[1, 2]}>
                  <BannerCreate />
                </RoleBasedGuard>
              )
            },
            {
              path: 'new',
              element: (
                <RoleBasedGuard accessibleRoles={[1, 2]}>
                  <BannerCreate />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'whatsapp',
          children: [
            {
              path: 'connect',
              element: (
                <RoleBasedGuard accessibleRoles={[1, 2, 3, 4, 5]}>
                  <WhatsAppConnect />
                </RoleBasedGuard>
              )
            },
            {
              path: 'pay',
              element: (
                <RoleBasedGuard accessibleRoles={[1, 2, 3, 5]}>
                  <WhatsappPay />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'shop',
          children: [
            {
              path: '/',
              element: (
                <RoleBasedGuard accessibleRoles={[3, 5]}>
                  <Shop />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'payment',
          children: [
            {
              path: '/my-account',
              element: (
                <RoleBasedGuard field="pagamentos" accessibleRoles={[1, 2, 3, 4, 5]}>
                  <PaymentOnline />
                </RoleBasedGuard>
              )
            },
            {
              path: '/history',
              element: (
                <RoleBasedGuard field="pagamentos" accessibleRoles={[1, 2, 3, 4, 5]}>
                  <OnlinePaymentsHistory />
                </RoleBasedGuard>
              )
            },
            {
              path: '/online',
              element: (
                <RoleBasedGuard field="pagamentos" accessibleRoles={[1, 2, 3, 4, 5]}>
                  <OnlinePayment />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'attendance',
          children: [
            { path: '/', element: <Navigate to="/dashboard/" replace /> },
            {
              path: 'all',
              element: (
                <RoleBasedGuard accessibleRoles={[1, 2, 3, 4]}>
                  <AttendanceList />
                </RoleBasedGuard>
              )
            },
            {
              path: 'new',
              element: (
                <RoleBasedGuard accessibleRoles={[1, 2]}>
                  <AttendanceNewPost />
                </RoleBasedGuard>
              )
            },
            {
              path: '/:name/edit',
              element: (
                <RoleBasedGuard accessibleRoles={[1, 2]}>
                  <AttendanceNewPost />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'content',
          children: [
            { path: '/', element: <Navigate to="/dashboard/content/list" replace /> },
            {
              path: 'list',
              element: (
                <RoleBasedGuard>
                  <ContentPosts />
                </RoleBasedGuard>
              )
            },
            {
              path: 'admin',
              element: (
                <RoleBasedGuard accessibleRoles={[1, 2]}>
                  <ContentList />
                </RoleBasedGuard>
              )
            },
            {
              path: '/:name/edit',
              element: (
                <RoleBasedGuard accessibleRoles={[1, 2]}>
                  <ContentNewPost />
                </RoleBasedGuard>
              )
            },
            {
              path: 'new',
              element: (
                <RoleBasedGuard accessibleRoles={[1, 2]}>
                  <ContentNewPost />
                </RoleBasedGuard>
              )
            },
            {
              path: ':slug',
              element: (
                <RoleBasedGuard>
                  <ContentPost />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'marketing',
          children: [
            { path: '/', element: <Navigate to="/dashboard/marketing/list" replace /> },
            {
              path: 'list',
              element: (
                <RoleBasedGuard>
                  <MarketingPosts />
                </RoleBasedGuard>
              )
            },
            {
              path: 'admin',
              element: (
                <RoleBasedGuard accessibleRoles={[1, 2]}>
                  <MarketingList />
                </RoleBasedGuard>
              )
            },
            {
              path: '/:name/edit',
              element: (
                <RoleBasedGuard accessibleRoles={[1, 2]}>
                  <MarketingNewPost />
                </RoleBasedGuard>
              )
            },
            {
              path: 'new',
              element: (
                <RoleBasedGuard accessibleRoles={[1, 2]}>
                  <MarketingNewPost />
                </RoleBasedGuard>
              )
            },
            {
              path: ':slug',
              element: (
                <RoleBasedGuard>
                  <MarketingPost />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'insight',
          children: [
            { path: '/', element: <Navigate to="/dashboard/insight/top-licenseds" replace /> },
            {
              path: 'top-licenseds',
              element: (
                <RoleBasedGuard accessibleRoles={[1, 2]}>
                  <TopLicensedsList />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'contract',
          children: [
            { path: '/', element: <Navigate to="/dashboard/contract/list" replace /> },
            {
              path: 'list',
              element: (
                <RoleBasedGuard>
                  <ContractPosts />
                </RoleBasedGuard>
              )
            },
            {
              path: 'admin',
              element: (
                <RoleBasedGuard accessibleRoles={[1, 2]}>
                  <ContractList />
                </RoleBasedGuard>
              )
            },
            {
              path: '/:name/edit',
              element: (
                <RoleBasedGuard accessibleRoles={[1, 2]}>
                  <ContractNewPost />
                </RoleBasedGuard>
              )
            },
            {
              path: 'new',
              element: (
                <RoleBasedGuard accessibleRoles={[1, 2]}>
                  <ContractNewPost />
                </RoleBasedGuard>
              )
            },
            {
              path: ':slug',
              element: (
                <RoleBasedGuard>
                  <ContractPost />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'coming-soon',
          children: [{ path: '/', element: <ComingSoon /> }]
        },
        {
          path: 'log',
          children: [
            { path: '/', element: <Navigate to="/dashboard/log/list" replace /> },
            {
              path: 'list',
              element: (
                <RoleBasedGuard accessibleRoles={[1, 2, 3, 4, 5, 6]}>
                  <LogList />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'config',
          children: [
            {
              path: '/',
              element: (
                <RoleBasedGuard accessibleRoles={[1, 2]}>
                  <Config />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'push',
          children: [
            { path: '/', element: <Navigate to="/dashboard/push/list" replace /> },
            {
              path: 'list',
              element: (
                <RoleBasedGuard field="push" accessibleRoles={[1, 3]}>
                  <PushList />
                </RoleBasedGuard>
              )
            },
            {
              path: 'new',
              element: (
                <RoleBasedGuard field="push" accessibleRoles={[1, 3]}>
                  <PushCreate />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'e-commerce',
          children: [
            {
              path: '/',
              element: (
                <RoleBasedGuard accessibleRoles={[3, 5]}>
                  <Ecommerce />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'import',
          children: [
            {
              path: '/',
              element: (
                <RoleBasedGuard accessibleRoles={[3, 4]}>
                  <Import />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'restaurant',
          children: [
            { path: '/', element: <Navigate to="/dashboard/restaurant/list" replace /> },
            {
              path: 'list',
              element: (
                <RoleBasedGuard field="estabelecimentos" accessibleRoles={[1, 2, 3, 4]}>
                  <RestaurantList />
                </RoleBasedGuard>
              )
            },
            {
              path: 'admin',
              element: (
                <RoleBasedGuard field="estabelecimentos" accessibleRoles={[1, 2]}>
                  <RestaurantListAdmin />
                </RoleBasedGuard>
              )
            },
            {
              path: 'new',
              element: (
                <RoleBasedGuard field="estabelecimentos" accessibleRoles={[1, 2, 3, 4]}>
                  <RestaurantCreate />
                </RoleBasedGuard>
              )
            },
            {
              path: '/:name/edit',
              element: (
                <RoleBasedGuard accessibleRoles={[1, 2, 3, 4]}>
                  <RestaurantCreate />
                </RoleBasedGuard>
              )
            },
            {
              path: 'edit',
              element: (
                <RoleBasedGuard accessibleRoles={[5, 6]}>
                  <RestaurantShopkeeper />
                </RoleBasedGuard>
              )
            },
            {
              path: 'opening',
              element: (
                <RoleBasedGuard accessibleRoles={[5, 6]}>
                  <OpeningHours />
                </RoleBasedGuard>
              )
            },
            {
              path: 'rating',
              element: (
                <RoleBasedGuard accessibleRoles={[5, 6]}>
                  <RatingList />
                </RoleBasedGuard>
              )
            }
          ]
        }
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    {
      path: '/',
      children: [{ path: '/', element: <Navigate to="/dashboard/" replace /> }]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const FirstAccess = Loadable(lazy(() => import('../pages/authentication/FirstAccess')));
const VerifyCode = Loadable(lazy(() => import('../pages/authentication/VerifyCode')));
const NewPassword = Loadable(lazy(() => import('../pages/authentication/NewPassword')));
// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const GeneralAppLic = Loadable(lazy(() => import('../pages/dashboard/GeneralAppLic')));
const GeneralAppAdm = Loadable(lazy(() => import('../pages/dashboard/GeneralAppAdm')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
const GeneralBanking = Loadable(lazy(() => import('../pages/dashboard/GeneralBanking')));
const LicensedList = Loadable(lazy(() => import('../pages/dashboard/LicensedList')));
const LicensedPermissions = Loadable(lazy(() => import('../pages/dashboard/Licensed/LicensedPermissions')));
const LicensedCreate = Loadable(lazy(() => import('../pages/dashboard/LicensedCreate')));
const RestaurantCreate = Loadable(lazy(() => import('../pages/dashboard/RestaurantCreate')));
const RestaurantList = Loadable(lazy(() => import('../pages/dashboard/RestaurantList')));
const RestaurantListAdmin = Loadable(lazy(() => import('../pages/dashboard/RestaurantListAdmin')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const Reports = Loadable(lazy(() => import('../pages/dashboard/Reports/Reports')));
const DistrictCreate = Loadable(lazy(() => import('../pages/dashboard/DistrictCreate')));
const DistrictList = Loadable(lazy(() => import('../pages/dashboard/DistrictList')));
const ReportList = Loadable(lazy(() => import('../pages/dashboard/ReportList')));

const AsaasCreate = Loadable(lazy(() => import('../pages/dashboard/AsaasCreate')));

const LogList = Loadable(lazy(() => import('../pages/dashboard/LogList')));

const Config = Loadable(lazy(() => import('../pages/dashboard/Config')));
const Shop = Loadable(lazy(() => import('../pages/dashboard/Shop')));

const DeliveryFeeList = Loadable(lazy(() => import('../pages/dashboard/DeliveryFeeList')));

const RestaurantShopkeeper = Loadable(lazy(() => import('../pages/dashboard/RestaurantShopkeeper')));
const OpeningHours = Loadable(lazy(() => import('../pages/dashboard/OpeningHours')));

const CategoryList = Loadable(lazy(() => import('../pages/dashboard/CategoryList')));
const CategoryCreate = Loadable(lazy(() => import('../pages/dashboard/CategoryCreate')));

const ProductCategoryList = Loadable(lazy(() => import('../pages/dashboard/ProductCategoryList')));
const ProductCategoryCreate = Loadable(lazy(() => import('../pages/dashboard/ProductCategoryCreate')));

const ContentCategoryList = Loadable(lazy(() => import('../pages/dashboard/ContentCategoryList')));
const ContentCategoryCreate = Loadable(lazy(() => import('../pages/dashboard/ContentCategoryCreate')));

const MarketingCategoryList = Loadable(lazy(() => import('../pages/dashboard/MarketingCategoryList')));
const MarketingCategoryCreate = Loadable(lazy(() => import('../pages/dashboard/MarketingCategoryCreate')));

const ProductList = Loadable(lazy(() => import('../pages/dashboard/ProductList')));
const ProductListV2 = Loadable(lazy(() => import('../pages/dashboard/Products/ProductList')));
const ProductModelListV2 = Loadable(lazy(() => import('../pages/dashboard/Products/ProductModelList')));

const ProductModelList = Loadable(lazy(() => import('../pages/dashboard/ProductModelList')));
const ProductModelListShop = Loadable(lazy(() => import('../pages/dashboard/ProductModelListShop')));

const ProductCreate = Loadable(lazy(() => import('../pages/dashboard/ProductCreate')));

const ProductModelCreate = Loadable(lazy(() => import('../pages/dashboard/ProductModelCreate')));

const RequestList = Loadable(lazy(() => import('../pages/dashboard/RequestList')));
const RequestListTest = Loadable(lazy(() => import('../pages/dashboard/RequestListTest')));
const RequestAllList = Loadable(lazy(() => import('../pages/dashboard/RequestAllList')));
const ReportDetails = Loadable(lazy(() => import('../pages/dashboard/ReportDetails')));

const RatingList = Loadable(lazy(() => import('../pages/dashboard/RatingList')));
const CustomerList = Loadable(lazy(() => import('../pages/dashboard/CustomerList')));
const CustomerAccount = Loadable(lazy(() => import('../pages/dashboard/CustomerAccount')));

const AccountsList = Loadable(lazy(() => import('../pages/dashboard/AccountsList')));

const CouponCreate = Loadable(lazy(() => import('../pages/dashboard/CouponCreate')));
const CouponTemplateCreate = Loadable(lazy(() => import('../pages/dashboard/CouponTemplateCreate')));
const CouponShop = Loadable(lazy(() => import('../pages/dashboard/CouponShop')));
const CouponList = Loadable(lazy(() => import('../pages/dashboard/CouponList')));
const FidelityCreate = Loadable(lazy(() => import('../pages/dashboard/FidelityCreate')));

const CampaignsList = Loadable(lazy(() => import('../pages/dashboard/CampaignsList')));
const CampaignsParticipe = Loadable(lazy(() => import('../pages/dashboard/CampaignsParticipe')));
const CampaignsCreate = Loadable(lazy(() => import('../pages/dashboard/CampaignsCreate')));

const FidelityDetails = Loadable(lazy(() => import('../pages/dashboard/FidelityDetails')));
const FidelityList = Loadable(lazy(() => import('../pages/dashboard/FidelityList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));

const DeliveryManList = Loadable(lazy(() => import('../pages/dashboard/DeliveryManList')));

const ReportHistory = Loadable(lazy(() => import('../pages/dashboard/ReportHistory')));
const DeliveryManCreate = Loadable(lazy(() => import('../pages/dashboard/DeliveryManCreate')));

const ContentNewPost = Loadable(lazy(() => import('../pages/dashboard/ContentNewPost')));
const ContentPost = Loadable(lazy(() => import('../pages/dashboard/ContentPost')));
const ContentPosts = Loadable(lazy(() => import('../pages/dashboard/ContentPosts')));
const ContentList = Loadable(lazy(() => import('../pages/dashboard/ContentList')));

const MarketingNewPost = Loadable(lazy(() => import('../pages/dashboard/MarketingNewPost')));
const MarketingPost = Loadable(lazy(() => import('../pages/dashboard/MarketingPost')));
const MarketingPosts = Loadable(lazy(() => import('../pages/dashboard/MarketingPosts')));
const MarketingList = Loadable(lazy(() => import('../pages/dashboard/MarketingList')));

const ContractNewPost = Loadable(lazy(() => import('../pages/dashboard/ContractNewPost')));
const ContractPost = Loadable(lazy(() => import('../pages/dashboard/ContractPost')));
const ContractPosts = Loadable(lazy(() => import('../pages/dashboard/ContractPosts')));
const ContractList = Loadable(lazy(() => import('../pages/dashboard/ContractList')));

const AttendanceList = Loadable(lazy(() => import('../pages/dashboard/AttendanceList')));
const AttendanceNewPost = Loadable(lazy(() => import('../pages/dashboard/AttendanceNewPost')));

const PushList = Loadable(lazy(() => import('../pages/dashboard/PushList')));
const PushCreate = Loadable(lazy(() => import('../pages/dashboard/PushCreate')));

const BannerCreate = Loadable(lazy(() => import('../pages/dashboard/BannerCreate')));
const BannerList = Loadable(lazy(() => import('../pages/dashboard/BannerList')));
const BannerLicList = Loadable(lazy(() => import('../pages/dashboard/BannerLicList')));

const WhatsAppConnect = Loadable(lazy(() => import('../pages/dashboard/WhatsAppConnect')));

const Manual = Loadable(lazy(() => import('../pages/dashboard/Manual')));

const PaymentOnline = Loadable(lazy(() => import('../pages/dashboard/PaymentOnline')));
const OnlinePayment = Loadable(lazy(() => import('../pages/dashboard/Payments/OnlinePayment')));
const OnlinePaymentsHistory = Loadable(lazy(() => import('../pages/dashboard/Payments/OnlinePaymentsHistory')));

const WhatsappPay = Loadable(lazy(() => import('../pages/dashboard/WhatsappPay')));

const Ecommerce = Loadable(lazy(() => import('../pages/dashboard/Ecommerce')));

const Import = Loadable(lazy(() => import('../pages/dashboard/Import')));

const TopLicensedsList = Loadable(lazy(() => import('../pages/dashboard/Insights/TopLicensedsList')));

// Main
const ComingSoon = Loadable(lazy(() => import('../pages/dashboard/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));

const HeatMap = Loadable(lazy(() => import('../pages/dashboard/HeatMap')));
